.logo-slider {
    display: flex;
    justify-content: start;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
  
  }
  
  .lg {
    background: rgb(255, 255, 255);
    background: linear-gradient(267deg, rgba(255, 255, 255, 0) 74%, rgba(255,255,255,1) 98%);
  
  }
  
  .logo-track {
    display: flex;
    animation: scroll 12s linear infinite;
    will-change: transform;
  }
  
  .logo-track-reverse {
    display: flex;
    animation: scrollreverse 12s linear infinite;
    will-change: transform;
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
  
    to {
      transform: translateX(-40%);
    }
  
  }
  
  
  @keyframes scrollreverse {
    from {
      transform: translateX(-40%);
    }
  
    to {
      transform: translateX(0);
    }
  
  }
   