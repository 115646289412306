.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    /* padding: 1rem 2rem; */
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .navbar.scrolled {
    background-color: #1E1E1E; /* Change to your desired background color */
  }